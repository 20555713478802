import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/heading"

import SEO from "../components/SEO"

const PrivacyPolicy = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Privacy Policy"
      description="Stuarts Coaches is committed to providing quality services to you and this
      policy outlines our ongoing obligations to you in respect of how we manage
      your Personal Information."
    />
    <Heading title="Privacy Policy" />
    <p>
      Stuarts Coaches is committed to providing quality services to you and this
      policy outlines our ongoing obligations to you in respect of how we manage
      your Personal Information.
    </p>
    <p>
      We have adopted the Australian Privacy Principles (APPs) contained in the
      Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which
      we collect, use, disclose, store, secure and dispose of your Personal
      Information.
    </p>
    <p>
      A copy of the Australian Privacy Principles may be obtained from the
      website of The Office of the Australian Information Commissioner at{" "}
      <a href="www.aoic.gov.au">www.aoic.gov.au</a>
    </p>
    <p className="sub-heading">
      What is Personal Information and why do we collect it?
    </p>
    <p>
      Personal Information is information or an opinion that identifies an
      individual. Examples of Personal Information we collect include; names,
      addresses, email addresses, and phone numbers.
    </p>
    <p>
      This Personal Information is obtained in many ways including telephone, by
      email, via our website (www.stuartscoaches.com.au), cookies and from third
      parties. We don’t guarantee website links or policy of authorised third
      parties.
    </p>
    <p>
      We collect your Personal Information for the primary purpose of providing
      our services to you, providing information to our clients and marketing.
      We may also use your Personal Information for secondary purposes closely
      related to the primary purpose, in circumstances where you would
      reasonably expect such use or disclosure.
    </p>
    <p>
      When we collect Personal Information we will, where appropriate and where
      possible, explain to you why we are collecting the information and how we
      plan to use it.
    </p>
    <p className="sub-heading">Sensitive Information</p>
    <p>
      Sensitive information is defined in the Privacy Act to include information
      or opinion about such things as an individual's racial or ethnic origin,
      political opinions, membership of a political association, religious or
      philosophical beliefs, membership of a trade union or other professional
      body, criminal record or health information.
    </p>
    <p>Sensitive information will be used by us only:</p>
    <ul>
      <li>For the primary purpose for which it was obtained.</li>
      <li>
        For a secondary purpose that is directly related to the primary purpose.
      </li>
      <li>With your consent; or where required or authorised by law.</li>
    </ul>
    <p className="sub-heading">Third Parties</p>
    <p>
      Where reasonable and practicable to do so, we will collect your Personal
      Information only from you. However, in some circumstances, we may be
      provided with information by third parties. In such a case we will take
      reasonable steps to ensure that you are made aware of the information
      provided to us by the third party.
    </p>
    <p className="sub-heading">Disclosure of Personal Information</p>
    <p>
      Your Personal Information may be disclosed in a number of circumstances
      including the following:
    </p>
    <ul>
      <li>Third parties where you consent to the use or disclosure; and</li>
      <li>Where required or authorised by law.</li>
    </ul>
    <p className="sub-heading">Cookies</p>
    <p>
      Our website uses cookies to analyse website traffic and help us provide a
      better website visitor experience
    </p>
    <p className="sub-heading">Security of Personal Information</p>
    <p>
      Your Personal Information is stored in a manner that reasonably protects
      it from misuse and loss and from unauthorised access, modification or
      disclosure.
    </p>
    <p>
      When your Personal Information is no longer needed for the purpose for
      which it was obtained, we will take reasonable steps to destroy or
      permanently de-identify your Personal Information. However, most of the
      Personal Information is or will be stored in client files which will be
      kept by us for a minimum of 7 years.
    </p>
    <p className="sub-heading">Access to your Personal Information</p>
    <p>
      You may access the Personal Information we hold about you and to update
      and/or correct it, subject to certain exceptions. If you wish to access
      your Personal Information, please contact us in writing.
    </p>
    <p>
      Stuarts Coaches will not charge any fee for your access request but may
      charge an administrative fee for providing a copy of your Personal
      Information.
    </p>
    <p>
      In order to protect your Personal Information, we may require
      identification from you before releasing the requested information.
    </p>
    <p className="sub-heading">
      Maintaining the quality of your Personal Information
    </p>
    <p>
      It is important to us that your Personal Information is up to date. We
      will take reasonable steps to make sure that your Personal Information is
      accurate, complete and up-to-date. If you find that the information we
      have is not up to date or is inaccurate, please advise us as soon as
      practicable so we can update our records and ensure we can continue to
      provide quality services to you.
    </p>
    <p className="sub-heading">Policy Updates</p>
    <p>
      This Policy may change from time to time and is available on our website.
    </p>
    <p className="sub-heading">Privacy Policy Complaints and Enquiries</p>
    <p>
      If you have any queries or complaints about our Privacy Policy please
      contact us via our Contact form.
    </p>
  </Layout>
)

export default PrivacyPolicy
